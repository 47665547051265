<template>
  <ConfirmationAlert />
  <NotificationToast />
  <ErrorAlertComponent />
  <router-view />
</template>
<script>
import { defineComponent } from 'vue'
import NotificationToast from '@/components/NotificationToast.vue'
import ErrorAlertComponent from '@/components/ErrorAlertComponent.vue';
export default defineComponent({
  mounted() {
    console.log('ta funcionando')
  },
  created() {
    document.title = `${process.env.VUE_APP_APP_NAME.toUpperCase()} | CMS`
  },
  components: {
    NotificationToast,
    ErrorAlertComponent
  }

})
</script>
